
  @import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap');
  @import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap");

body{
  margin: 0;
  padding: 0;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}



*, ::before, ::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
h1, h2, p {
  margin: 0;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
  color: black;
}
img {
  max-width: 100%;
  height: auto;
  display: block;
}
:root {
  --font: "Caveat", cursive ;

  --yellow: #f5c32c;
  --orange: #fca61f;
  --black: #3665ff;
  --gray: #788097;
  --white: #ffffff;
  --background: #fcfcfc;
  --purple: rgb(238 210 255);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --orangeCard: rgba(252, 166, 31, 0.45);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
  --font : 'Caveat', cursive ;
  --font-Original : 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}
@media (min-width: 768px) {
  .container {
    width: 650px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1050px;
  }
}

@mixin mobile {
  @media (max-width:900px) {
    @content
  }
}
@mixin S_mobile {
  @media (max-width:500px) {
    @content
  }
}
@mixin tablet {
  @media (max-width:1200px) {
    @content
  }
}


.container {
  width: 90%;
  margin: auto;
  padding: 40px 0;
}

.App {
  position: relative;
  width: 100%;
  overflow: hidden;
  color: var(--black);
}
.button {
  background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
  box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 16px;
  padding: 11px 26px 11px 26px;
  position: relative;
  right: -80%;

}
.button:hover {
  background: white;
  cursor: pointer;
  border: 1px solid var(--orange);
  color: var(--orange);
}

@keyframes topUp {
  to {top: 0;
  left: 20px;}
}
@keyframes Duration {
  to{top : -85px; }
}
@keyframes waite {
  from{margin-left: -150px;}
  to{margin:3px 0}
}

.HOME{
  padding: 0 !important;
  margin: 0 !important;
  width: 100%;
  position: relative;
  height: 100vh;
  padding-right:40px ;
  background: linear-gradient(to right, #434343, #000000); 
}
.backGround {
  background: linear-gradient(to right, #434343, #000000) ; 

}

.Header{
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  >div{
    font-family: 'Caveat', cursive !important;
    font-size: 40px;
    a{
      font-family: var(--font);
      text-transform: uppercase;
      font-size: 40px;
      font-weight: bolder;
      -webkit-text-stroke: 1px rgb(255, 255, 255);
      color: white;
      @include mobile{
       font-size: 20px;
       letter-spacing: 1px;
      }
      span{
        margin: 0 10px;
      }
    }
  }
  ul{
    display: flex;
    align-items: center;
    @media (max-width:800px) {
      background: linear-gradient(90.13deg,   #2347e9ad 35%, #804dee 130%);
      top: 0;
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 40%;
      height: 100vh;
      right: 0;
      transition: .5s;
      z-index: 100000000;
      align-items: flex-start;
      justify-content:center;
      gap: 30px;
      li{
        cursor: pointer;
        a{
          display: block;
          font-size: 30px;
          text-transform: capitalize;
          &:hover{
            letter-spacing: 2px;
            transition: .5s;
            -webkit-text-stroke: 1px white;
          }
        }

      }
    }
    li{
      margin: 0 10px;
      a{
        letter-spacing: 1px;

        color: white;
        font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        &:hover{
          opacity: .8;
          transition: .5s;
        }
      }
    }
  }

  .toggle{
    display: none;
    position: relative;
    z-index: 40000000000000000;
    color: white;
    cursor: pointer;
    @include mobile{
      font-size: 30px;
      letter-spacing: 1px;
     }
    @media (max-width:800px) {
      display: block;
    }
    i{


    }
  }
}

  @keyframes up-downIMg {
    0%, 100% {
      top: 50%;
    }
    50% {
      top: 49.7%;
    }
  }
  @keyframes up-down {
    0%, 100% {
      top: 0;
    }
    50% {
      top: 8px;
    }
  }

.home {
  position: relative;
  right: 0 !important;
  height: calc(100vh - 120px);
  top: -80px;
  left: 0 !important;
  transition: 1s;
  animation: topUp 1s forwards;
  width: 100%;
  .shape{
    width: 100%;
    height: 100%;
    position: relative;
    animation: 4s up-down linear infinite;

    .line_1{
      position: absolute;
      display: block;
      width: 100px;
      height: 400px;
      top: 50%;
      left: 38%;
      transform: translate(-38% , -50%);
      z-index: 10000;
      border-left: 4px solid var(--black) ;
      border-bottom: 4px solid var(--black);
      @include mobile {
        height: 290px !important;
        left: 30%;
      }
      @include S_mobile {
        height: 290px !important;
        left: 20%;
      }
    }
    .line_2{
      position: absolute;
      display: block;
      width: 100px;
      height: 400px;
      top: 50%;
      right: 38%;
      transform: translate(38% , -50%);
      z-index: 10000;
      border-top: 4px solid var(--black) ;
      border-right: 4px solid var(--black);
      @include mobile {
        height: 290px !important;
        right: 30%;
      }
      @include S_mobile {
        height: 290px !important;
        right: 20%;
      }
    }
    .bullet_1{
      position: absolute;
      display: block;
      width: 16px;
      height: 16px;
      background-color: var(--black);
      border-radius: 50%;
      top: -3%;
      right: 103%;
      border: 1px dashed lightgray;
    }
    .bullet_2{
      position: absolute;
      display: block;
      width: 14px;
      height: 14px;
      background-color: var(--black);
      border-radius: 50%;
      top: -2.5%;
      right: 125%;
      border: 1px dashed lightgray;
    }
    .bullet_3{
      position: absolute;
      display: block;
      width: 12px;
      height: 12px;
      background-color: var(--black);
      border-radius: 50%;
      top: -2%;
      right: 145%;
      border: 1px dashed lightgray;
    }

    .bullet_4{
      position: absolute;
      display: block;
      width: 16px;
      height: 16px;
      background-color: var(--black);
      border-radius: 50%;
      bottom: -2.5%;
      left: 103%;
      border: 1px dashed lightgray;
    }
    .bullet_5{
      position: absolute;
      display: block;
      width: 14px;
      height: 14px;
      background-color: var(--black);
      border-radius: 50%;
      bottom: -2.2%;
      left: 125%;
      border: 1px dashed lightgray;
    }
    .bullet_6{
      position: absolute;
      display: block;
      width: 12px;
      height: 12px;
      background-color: var(--black);
      border-radius: 50%;
      bottom: -2%;
      left: 145%;
      border: 1px dashed lightgray;
    }
  }
    
    .cover{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50% , -50%);
      animation: 4s up-downIMg linear infinite;
      .innerCover{
        @include tablet {
          width: 300px;
          height: 300px;
        }
        @include mobile {
          width: 250px !important;
          height: 250px !important;
        }
        @include S_mobile {
          width: 200px !important;
          height: 200px !important;
        }
        width: 300px;
        height: 300px;
        object-fit: contain;
        border-radius: 50%;
        animation: siz 5s forwards;
        left: 50%;
        top:50%;
        transform: translate(-50% , -50%);
        position: absolute;
        // background-color: var(--background);
        box-shadow: 2px 3px 10px #2347e9 , -3px -4px 10px #2347e9;
        background: conic-gradient(#2347e9 , transparent  , #2347e9 );
      }

      img {
        @include tablet {
          width: 300px;
          height: 300px;
        }
        @include mobile {
          width: 250px !important;
          height: 250px !important;
        }
        @include S_mobile {
          width: 200px !important;
          height: 200px !important;
        }
        width: 300px;
        height: 300px;
        object-fit: contain;
        border-radius: 50%;
        animation: siz 5s forwards;
        left: 50%;
        top:50%;
        transform: translate(-50% , -50%);
        position: absolute;
      @keyframes  siz {
        from{opacity: 0;}
        to{opacity: 1;}
      }
      }
    }

  ul{
    position: relative;
    left: -60px;
    @include mobile{
      left: -44px !important;
    }
    a{
    @include mobile{
      width: 35px;
    height: 35px;
    font-size: 24px;
    }
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 30px;
    margin-bottom: 10px;
    border: 4px solid #3563f9;
    padding: 5px;
    box-shadow: 0 0 15px #2196F3;
    border-radius: 50%;
    transition: all 1s;
    &:hover i{
      transform: rotate(360deg)  !important;
    }
    &:hover h1 {
      opacity: 1;
      width: 120px;
      cursor: pointer;
      @include mobile {
        width: 80px;
      }
    }
    &:hover h1 {
      animation: 2s display forwards ;
    }
    h1{
      color: white;
      position: absolute;
      font-size: 18px;
      width: 0;
      background: #3563f9;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 85%;
      transform: translate(0%, -50%);
      transition: 1s;
      white-space: nowrap;
      opacity: 0;
      height: 100%;
      z-index: -1;
      font-family: var(--font);
      border-radius: 0 20px 20px 0;
    }
    i{
      box-sizing: border-box;
    color: white;
    transition: 1s;
    width: 50px;
    height: 50px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    &.fa-facebook{color:#0872e4 }
    &.fa-envelope{color:#d24635 }
    &.fa-whatsapp{color:#7ed970 }
    &.fa-github{color:#000000 }
    &.fa-linkedin{color:#0a61b9 }
    }
  }
}

@keyframes display {
  from {opacity: 0;}
  to {opacity: 1;}
}

  .Download{
    color: white;
    position: absolute;
    display: block;
    bottom: 0;
    left: -20px;
    padding: 5px 10px;
    text-align: center;
    background-color: var(--black);
    letter-spacing: -1px;
    font-family: var(--font);
    border-radius: 6px;
    cursor: pointer;
    transition: .4s;
    letter-spacing: 1px;
    white-space: nowrap;
    &:hover{opacity: .8;}
    @include mobile {
      font-size: 14px;
      left: 5px;
    }
  }
}

.skill1 {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 100px;
  min-height: 50vh;
  .container {
    display: grid;
    grid-template-columns: 1fr 250px;
    gap: 20px;
    align-items: center;
    justify-content: center;
    // width: 1000px;
    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }
  h2{
    text-align: center;
    text-shadow: 10px 15px 20px black;
    .one{
      color: white;
      margin-right: 5px;
      @include mobile{
        font-size:20px  ;
      }
    }
    .two{
      color: #3665ff;
      @include mobile{
        font-size:20px  ;
      }
    }
    .three{
      color: #3665ff;
      font-size: 50px;
      margin-right: 10px;
      @include mobile{
        font-size:35px  ;
      }
    }
    .four{
      color: white;
      font-size: 40px;
      font-weight: normal;
      @include mobile{
        font-size:35px  ;
      }
    }
    .five{
      font-size: 18px;
      color: white;
      @include mobile{
        font-size:18px  ;
      }
    }
    .six{
      font-size: 40px;
      font-family: var(--font);
      color: white;
      -webkit-text-stroke: 1px rgb(255, 255, 255);
      @include mobile{
        font-size:30px  ;
      }
    }
  }
  h3 {
    padding: 30px 20px 0;
    font-size: 15px;
    font-weight: normal;
    color: white;
    line-height: 1.5;
    letter-spacing: 2px;
    font-weight: normal;
    width: 70%;
    margin: auto;
    -webkit-text-stroke: 1px white;
    text-align: left;
    @include mobile{
      font-size:14px !important ;
      width: 90%;
      letter-spacing: 1px;
    }
    &::first-line {
      font-family: var(--font);
      font-size: 25px;
      margin: 5px 0;
      font-variant: small-caps;
      letter-spacing: 3px;
      color: transparent;
    }
    @media (max-width: 430px) {
      font-size: 14px;
      line-height: 1.2;
    }
  }
  .solar-system {
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 20%;
    .orbit {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #007bb6;
      border-radius: 50%;
    }
    .All {
      width: 165px;
      height: 165px;
      animation: spin 12s linear infinite;
      position: relative;
      border-color: #f1ec50;
      box-shadow: 0 0 10px #f1ec50 inset, 0 0 10px #f1ec50;
    }
    .planet {
      position: absolute;
      top: -5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    .js {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f1ec50;
    }
    .html {
      width: 110px;
      height: 110px;
      animation: spin 8s linear 0s infinite;
      color: #f18282;
      border-color: #f18282;
      box-shadow: 0 0 10px #f18282f0 inset, 0 0 10px #f18282ef;
    }
    .css {
      width: 60px;
      height: 60px;
      color: #2347e9;
      animation: spin 8s linear 0s infinite;
      position: relative;
      border-color: #2347e9;
      box-shadow: 0 0 10px #2347e9ac inset, 0 0 10px #2347e99d;
    }
    .sun {
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      width: 30px;
      height: 30px;
      color: rgba(243, 243, 32, 0.758);
      box-shadow: 0 0 10px rgb(243, 243, 14) inset, 0 0 10px rgb(243, 243, 14);
    }
    .react {
      width: 180px;
      height: 180px;
      display: flex;
      color: #00cdf2;
      &::after {
        position: absolute;
        top: -31px;
        left: -26px;
        content: "";
        border: 1px solid;
        width: 230px;
        height: 230px;
        border-radius: 50%;
        box-shadow: 0 0 10px #00cdf2 inset, 0 0 10px #00cdf2;
      }
    }
    @keyframes spin {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }
}


.skill2{
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  min-height: 80vh;
  position: relative;
  .container{
    padding: 0 ;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    .cover{
      order: 2;
      position: relative;
      width: 500px;
      margin-left: 30px;
      border-radius: 10px;
      overflow: hidden;
      cursor: pointer;
      transition: .5s ;
      &::before{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        clip-path: polygon(0% 0%, 50% 50%, 0% 100%);
        background-color:rgb(237, 177, 64);
        z-index: 4;
      }
      &:hover img{
        transform: scale(1.3) rotate(9deg);
      }
      &:hover::before{
        opacity: 0;
        transition: .5s;
      }
      @media (max-width:1200px) {
        display: none;
      }
      img{
        width: 100%;
        transition: .5s;
      }
    }
    .box{
      display: flex;
      flex-direction: column;
      margin: auto ;
      border-radius: 10px 10px 0 0;
      width: 600px;
      @media (max-width:600px) {
        width: 400px;
      }
      overflow: hidden;
      h2{
        position: relative;
        left: 40px;
        white-space: nowrap;
        @include mobile{
          font-size: 14px;
        }
        width: 250px;
        padding: 10px 10px 20px;
        font-size: 20px;
        color: transparent;
        font-family: var(--font-Original);
        color: white;
        letter-spacing: -1px;
        display: flex;
        span{
          left: 80px;
          position: absolute;
          margin-left: 20px; 
          white-space: nowrap;
        }
      }
      .progress{
        position: relative;
        @include mobile{
          font-size: 14px;
          width: 80%;
          margin: auto;
        }
        width: 100%;
        height: 5px;
        border-radius: 10px;
        background-color: rgb(223, 223, 223);        
        span{
          
          position: absolute;
          top: 0;
          left: -1px;
          background-color: var(--black);
          height: 100%;
          transition: 2s;
          border-radius:10px 0 0 10px;
          opacity: 0;
          
          &::before{
            content: attr(data-width);
            @include mobile{
              font-size: 14px;
              top: -41px;
              right:-16px ;
            }
            position: absolute;
            top: -47px;
            font-size: 17px;
            background-color: var(--black);
            padding: 4px;
            border-radius: 4px;
            right: -20px;
            color: transparent;
            -webkit-text-stroke: 0.6px white;
          }
          &::after{
            content: "";
            position: absolute;
            border: 10px solid transparent;
            border-top-color: var(--black);
            top: -12px;
            right: -9px;
          }
        }
      }
      .right{
        position: absolute;
        left: 120px;
        color: #eeebebbe;
        animation: Load 4s linear infinite;
      }
      @keyframes Load {
        0% , 100% {left: 120px;}
        50% {left: 180px;}
      }
    }
  }
}


.Portfolio{
  .container{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
    min-height: 100vh;
    gap: 10px;
    transition: .5s;
    // &:hover .cover {
    //   opacity: .3;
    // }
    .cover{
      opacity: .7;
      transition: .5s;
      border-radius: 10px;
      padding: 10px 10px 50px 10px ;
      width: 300px;
      height: 300px;
      background-color: #1510308f;
      margin: auto;
      transform-origin: left top;
      @include mobile{
        width: 200px;
        height: 200px;
      }
      &:hover{
        opacity: 1 !important;
        transform: scale(1.1);
      }
      &:hover ~.cover {
        transform: translate3d(0 , 40px , 0);
      }
      .box{
        position: relative;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        object-fit: contain;
        animation:  despear 6s forwards ;
        box-shadow: -12px 14px 89px -36px rgba(36, 53, 235, 0.89);
        overflow: hidden;
        &:hover img{
          transform: scale(1.1) rotate(4deg);
          opacity: .7;
        }
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: 1s;
          cursor: pointer;
        }
        a{
          @include mobile{
            width: 40px;
            height: 40px;
            font-size: 20px;
          }
          position: absolute;
          top: 5px;
          font-size: 40px;
          width: 60px;
          background-color: black;
          color: white;
          font-size: 30px;
          height: 60px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 10px 10px 10px black inset , 10px 10px 10px rgb(127, 124, 124) inset , 10px 40px 10px rgb(26, 61, 92) inset;
          &:hover{
            opacity: .7;
            transition: .5s;
            box-shadow: 10px 10px 10px rgb(112, 16, 16) inset , 10px 10px 10px rgb(65, 15, 183) inset , 10px 40px 10px rgb(28, 185, 28) inset;

          }
        }
        .githup{
          right: 10px;
        }
        .link{
          left: 10px;
        }
        
      }
      h3{
        text-shadow: 10px 10px 10px var(--black);
        white-space: nowrap;
        font-family: var(--font);
        font-size: 30px;
        color: white;
        -webkit-text-stroke: 2px --black;
        text-align: center;
        margin-top: 0;
        @include mobile{
          font-size: 18px;
          margin-top: 10px;
        }
      }
    }
  }
}

